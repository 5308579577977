import React from "react";
import { Container, Layout } from "components";
import { Hero, Loader, ImageAndTextBlock } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { Post_By_IdQuery } from "codegen";
import { graphql } from "gatsby";

interface BlogPostTemplate {
  data: Post_By_IdQuery;
}

const BlogPostTemplate = ({ data: { post, wp } }: BlogPostTemplate): JSX.Element => {
  const { title, seo, acfLayout } = post;
  const {
    webAppSettings: {
      webAppSettings: { columns: postFooterBlock },
    },
  } = wp;

  return (
    <Layout className="post-template" pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<h1>${post.title}</h1>`,
            compact: true,
            breadcrumbs: "none",
            image: post?.featuredImage?.node,
          }}
          // location={seo.breadcrumbs}
        />
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          <div className="post">
            {post.content && (
              <Container>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              </Container>
            )}
          </div>
        )}
        {postFooterBlock && postFooterBlock && (
          <ImageAndTextBlock data={{ style: "peak", columns: postFooterBlock, backgroundcolour: "TERTIARY" }} />
        )}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "posts" })(BlogPostTemplate);

export const PostByIdQuery = graphql`
  query POST_BY_ID($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostFields
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    wp {
      webAppSettings {
        webAppSettings {
          columns {
            contenttype
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, width: 1080)
                }
              }
            }
            imageaccent
          }
        }
      }
    }
  }
`;
